import { mapActions, mapState } from 'vuex'
import { myMixin } from '../../../../mixins'
import store from '../../../../store'

export default {
    mixins: [myMixin],
    data: () => ({
        collections: [],
        params: {},
        message: '',
        error: false,
        loading: false,
        meta: {},
        itemDelete: {},
        itemDeleteTotvs: {},
        showDeleteTotvs: false,
        settings: null,
        payloadFilter: null,
        showFilter: false,
        model: {},
        show: false,
        showForm: false,
        showFormBatch: false,
        showFormIndividual: false,
        loadindRefreshStudents: false,
        text: '',
        deleting: false,
        headers: [
            { text: 'Junção', value: 'id', align: 'left', sortable: false},
            { text: 'Campus', value: 'campuses', align: 'left', sortable: false},
            { text: 'Curso', value: 'courses', align: 'left', sortable: false},
            { text: 'Turno', value: 'shifts', align: 'left', sortable: false},
            { text: 'CH', value: 'work_load', align: 'left', sortable: false},
            { text: 'Turma | Disciplina', value: 'grids', align: 'left', sortable: false},
            { text: 'Ações', value: 'actions', sortable: false, align: 'left', width: '90px'}
        ]
    }),
    mounted(){
        this.settings = store.state.auth.acesso.instituicao.settings;
    },
    computed: {
        ...mapState('auth', ['acesso', 'user']),
      },
    methods: {
        ...mapActions('join', ['ActionFindJoins', 'ActionDeleteJoin', 'ActionJoinDestroyTotvs']),
        ...mapActions('controlPanel', ['ActionJoinStudentsRefresh']),
        
        showRemoveTotvs(item){

            this.itemDeleteTotvs = item;
            this.showDeleteTotvs = true;

        },
        
        removeTotvs(item) {
            
            this.deleting = true;
            this.message = '';
            
            this.ActionJoinDestroyTotvs({ id: item.id })
                .then((res) => {
                    this.message = res.message;
                    this.error = false
                    // this.getData();
                }).catch((error) =>{
                    this.message = error.message;
                    this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                    this.showDeleteTotvs = false;
                });

        },
        getData() {
            
            this.showForm = false;
            this.showFormIndividual = false;
            this.error = false;
            this.message = '';
            this.loading = true;
            let payload = this.params;

            payload.showAllCampuses = true;
            payload.with = 'category,grids.join,grids.subGroup,grids.teacher,grids.discipline,grids.group,grids.group.campus,grids.group.course,grids.group.shift,status,grids.workLoadType';

            delete payload.filter_relations;
            delete payload.find_columns;

            if(this.payloadFilter){
                
                if(this.payloadFilter.filter_relations){
                    payload.filter_relations = this.payloadFilter.filter_relations;
                }

                if(this.payloadFilter.find_columns){
                    payload.find_columns = this.payloadFilter.find_columns;
                }
            }

            this.ActionFindJoins(payload)
                .then((res) => {
                    this.collections = res.data;
                    this.meta = res.meta;
                })
                .finally(() => {
                    this.loading = false;
                })
        },

        refreshStudents(){
          
            this.loadindRefreshStudents = true;
            
            this.ActionJoinStudentsRefresh()
              .then((res) => {
                  
              }).finally(() => {
                this.loadindRefreshStudents = false;
                
              });
  
          },

        filter(payload){
            this.payloadFilter = payload;
            this.showFilter = false;
            this.getData();
        },

        deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteJoin({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                    this.message = (error.errors &&  error.errors.length > 0) ? error.errors[0] : error.message;
                    this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                })
        },

        getTotalStudents(item){

            var count = 0;

            item.grids.forEach(g => {
                count += g.students;
            });

            return count;

        },

        getTextJoin(grid){

            var text = grid.group.name + ' | ' + grid.discipline_name;

            if(text.length > 50){
                return text.substring(0, 50) + '...';
            }else{
                return text;
            }

        },

        isUniqueCampuses(item){

            var items = [];

            item.grids.forEach(g => {

                if(!items.includes(g.group.campus.id)){
                    items.push(g.group.campus.id);
                }

            });

            return items.length == 1;

        },

        isUniqueCourses(item){

            var items = [];

            item.grids.forEach(g => {
                if(!items.includes(g.group.course.id)){
                    items.push(g.group.course.id);
                }
            });

            return items.length == 1;

        },

        isUniqueShifts(item){

            var items = [];

            item.grids.forEach(g => {
                if(!items.includes(g.group.shift.id)){
                    items.push(g.group.shift.id);
                }
            });

            return items.length == 1;

        },

        isUniqueWorkLoads(item){

            var items = [];

            item.grids.forEach(g => {
                if(!items.includes(g.work_load_type.id)){
                    items.push(g.work_load_type.id);
                }
            });

            return items.length == 1;

        }

    },
    watch: {
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        }, //Função para tratar quando um item for exibido para deletar
        itemDelete(item_new, item_old){
            
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.show = true
                this.text = "Deseja excluir a junção?";
            } else {
                this.show = false
                this.text = ''
            }
        },
        show(show_new, show_old) {
            
            if(show_new != show_old && !show_new) {
                this.itemDelete = {};
            }
        },
        
        model(item_new, item_old){
            if(item_new !== item_old && this.model.id)
            {
                this.titleForm = 'Editar curso'
                this.showForm = true
            } else if(Object.keys(this.model).length === 0){
                this.titleForm = 'Cadastrar curso'
                this.showForm = false
            }           
        },

        showForm(show_new, show_old) {
            if(show_new != show_old && !show_new) {
                this.model = {};
            }
        },

        

    }
}